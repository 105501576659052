<template>
  <div class="card" :style="cardStyle" :onclick="addRemoveShow">
    <span class="show-name">{{ show.name }}</span>
    <div class="card-links">
      <a :class="linkClass">{{ linkText }}</a>
    </div>
  </div>
</template>

<script>
import localStorageService from '@/services/localStorageService.js';

export default {
  props: {
    show: {
      id: Number,
      name: String,
      backdrop_path: String
    }
  },
  data() {
    return {
      inLibrary: false
    }
  },
  computed: {
    bgImage () {
      // TODO: replace with call to /configuration endpoint
      return `https://image.tmdb.org/t/p/w780${this.show.backdrop_path}`
    },
    cardStyle () {
      return this.show.backdrop_path ?
        { backgroundImage: `url(${this.bgImage})` }
        : {}
    },
    linkText() {
      return this.inLibrary ? "Remove" : "Add"
    },
    linkClass() {
      return this.inLibrary ? "remove" : "add"
    }
  },
  methods: {
    addRemoveShow(event) {
      event.preventDefault();
      localStorageService.addRemoveShow(this.show);
      this.setInLibrary();
    },
    setInLibrary() {
      this.inLibrary = localStorageService.isShowInLibrary(this.show.id);
    }
  },
  created () {
    this.setInLibrary();
  }
}
</script>

<style scoped>
.card {
  display: inline-block;
  border: solid gray 2px;
  border-radius: 8px;
  margin: 16px;
  width: 26%;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: relative;
}
.show-name {
  font-weight: bold;
  padding: 8px 16px;
  color: white;
  border-radius: 8px;
  background-color: #55535394;
  position: relative;
  top: 20px;
  display: inline-flex;
  max-width: 85%;
}
.card-links {
  background-color: #333333bb;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
}
.card-links a.add {
  color: #60d06c;
}
.card-links a.remove {
  color: #ff5353;
}

@media (max-width: 500px) {
  .card {
    display: block;
    width: unset;
  }
}
</style>