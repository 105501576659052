<template>
  <div id="episode-view">
    <section id="episode-image">
      <img class="episodeImage" :src="fullImagePath" v-if="episodeImage" />
    </section>
    <section id="episode-info">
      <div id="show-name">{{showName}}</div>
      <div id="episode-title"><label>Title</label> {{episodeTitle}}</div>
      <div id="season-episode"><label>Season</label> {{seasonNumber}} <label>Episode</label> {{episodeNumber}}</div>
      <div id="air-date"><label>Air Date</label> {{airDate}}</div>
      <button id="button-get-new" :onclick="getEpisode">Something else</button>
    </section>
  </div>
</template>

<script>
import apiService from '@/services/apiService.js';
import localStorageService from '@/services/localStorageService.js';

export default {
  name: 'EpisodeView',
  data() {
    return {
      showName: "",
      seasonNumber: "",
      episodeNumber: "",
      episodeTitle: "",
      episodeImage: "",
      airDate: "",
      backdropSize: ""
    }
  },
  computed: {
    fullImagePath() {
      const tmdbImgBase = 'https://image.tmdb.org/t/p/';
      return `${tmdbImgBase}${this.backdropSize}${this.episodeImage}`;
    }
  },
  methods: {
    setBackdropWidth() {
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const backdropSizes = ['w300', 'w780', 'w1280', 'original'];

      for (const size of backdropSizes) {
        if (size === 'original') {
          this.backdropSize = size;
        }
        else if (parseInt(size.replace('w','')) > viewportWidth) {
          this.backdropSize = size;
          break;
        }
      }
    },
    getEpisode() {
      const showsIds = localStorageService.getShowIds();
      apiService.getEpisode(showsIds)
        .then(response => {
          if (response.data) {
            this.showName = response.data.show_name;
            this.seasonNumber = response.data.season_number;
            this.episodeNumber = response.data.episode_number;
            this.episodeTitle = response.data.episode_title;
            this.episodeImage = response.data.episode_image;
            this.airDate = response.data.air_date;
          } else {
            // TODO: replace with user facing message
            console.log("No data. Response = ", response);
          }
        })
        .catch(error => {
          // TODO: replace with user facing message
          console.error("error: ", error);
        });
    }
  },
  created() {
    this.setBackdropWidth();
    this.getEpisode();
  }
}
</script>

<style scoped>
#episode-view {
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "image info"
}
#episode-image {
  grid-area: image;
}
#episode-info {
  grid-area: info;
}
.episodeImage{
  width: 100%;
}
#show-name {
  font-weight: bold;
  font-size: 3rem;
}
#episode-title {
  font-weight: bold;
  font-size: 2.5rem;
}
#season-episode {
  font-weight: bold;
  font-size: 2rem;
}
#air-date {
  font-weight: bold;
  font-size: 1.5rem;
}
label {
  font-weight: normal;
  color: #999999;
}
#button-get-new {
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #198754;
  border-color: #198754;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
}
#button-get-new:hover {
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 .25rem rgba(60,153,110,.5);
}
@media (max-width: 1100px) {
  #episode-view {
    grid-template-columns: 1fr 1.5fr;
  }
  #show-name {
    font-size: 2rem;
  }
  #episode-title {
    font-size: 1.75rem;
  }
  #season-episode {
    font-size: 1.5rem;
  }
  #air-date {
    font-size: 1.25rem;
  }
}
@media (max-width: 860px) {
  #episode-view {
    grid-template-columns: 1fr 1.2fr;
  }
}
@media (max-width: 600px) {
  #episode-view {
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "info"
      "image";
  }
}
@media (max-width: 480px) {
  #show-name {
    font-size: 1.5rem;
  }
  #episode-title {
    font-size: 1.25rem;
  }
  #season-episode {
    font-size: 1rem;
  }
  #air-date {
    font-size: 1rem;
  }
  #button-get-new {
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
</style>