<template>
  <div class="home">
    <header>
      <h1>Pick For Me TV</h1>
      <article id="sub-heading">
        Don't want to pick something to watch? Don't even want to pick a show?
        <br />
        Use Pick For Me TV to randomly pick an episode of all your favorite shows!
      </article>
    </header>
    <br />
    <button id="button-get-new" :onclick="goToEpisode">Get episode</button>
    <ShowList :shows="shows" :searchMode="false" />
  </div>
</template>

<script>
import ShowList from '@/components/ShowList.vue';
import localStorageService from '@/services/localStorageService.js';

export default {
  name: 'HomeView',
  components: {
    ShowList
  },
  data() {
    return {
      shows: localStorageService.getShows()
    }
  },
  methods: {
    goToEpisode() {
      this.$router.push({ name: 'episode' });
    }
  }
}
</script>

<style scoped>
#sub-heading {
  font-size: 1.5rem;
}
#working-title {
  font-size: 1rem;
  margin-top: 1rem;
  font-style: italic;
}
#button-get-new {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #198754;
  border-color: #198754;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
}
#button-get-new:hover {
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 .25rem rgba(60,153,110,.5);
}
@media (max-width: 480px) {
  #button-get-new {
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
</style>