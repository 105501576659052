const axios = require('axios');
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
  getEpisode(shows) {
    return axios.get(`/episode?shows=${JSON.stringify(shows)}&c=${Math.floor(new Date() / 1000)}`);
  },
  search(name) {
    return axios.get(`/shows?name=${name}`);
  }
};