export default {
  addRemoveShow(show) {
    if (!localStorage.shows) {
      localStorage.shows = JSON.stringify([]);
    }
    let shows = JSON.parse(localStorage.shows);
    const show_local = shows.find(s => s.id == show.id);
    if (!show_local) {
      shows.push(show);
    } else {
      shows = shows.filter(s => s.id != show.id)
    }

    localStorage.shows = JSON.stringify(shows);
  },
  isShowInLibrary(id) {
    if (!localStorage.shows) {
      localStorage.shows = JSON.stringify([]);
    }
    const shows = JSON.parse(localStorage.shows);
    const show_local = shows.find(s => s.id == id);
    if (!show_local) {
      return false;
    } else {
      return true;
    }
  },
  getShowIds() {
    if (!localStorage.shows) {
      localStorage.shows = JSON.stringify([]);
    }
    const shows = JSON.parse(localStorage.shows);
    return shows.map(s => s.id);
  },
  getShows() {
    if (!localStorage.shows) {
      localStorage.shows = JSON.stringify([]);
    }
    return JSON.parse(localStorage.shows);
  }
}