<template>
  <section id="show-list">
    <h2>{{titleValue}}</h2>
    <div id="no-shows-message" v-if="shows.length === 0 && !searchMode">
      You have no shows in your library.
      Use <router-link :to="{ name: 'search' }">Search</router-link> to add shows to your library.
    </div>
    <span v-else v-for="show in shows" :key="show.id">
      <ShowCard :show="show"/>
    </span>
  </section>
</template>

<script>
import ShowCard from './ShowCard.vue';

export default {
  components: {
    ShowCard
  },
  props: {
    searchMode: Boolean,
    shows: {
      id: Number,
      name: String,
      backdrop_path: String
    }
  },
  computed: {
    titleValue() {
      if (this.searchMode) {
        return "Search Results";
      } else {
        return "Your Shows";
      }
    }
  }
}
</script>

<style scoped>
#no-shows-message {
  font-size: 1.5rem;
}
#show-list {
  border-top: double black 6px;
}
</style>