<template>
  <div class="search">
    <input v-model="searchQuery" @keyup.enter="doSearch" id="search-input" type="text" placeholder="Search for a show..." />
    <button id="search-button" :onclick="doSearch"><i class="fa-solid fa-magnifying-glass"></i></button>
    <ShowList :shows="shows" :searchMode="true" />
  </div>
</template>

<script>
import ShowList from '@/components/ShowList.vue';
import apiService from '@/services/apiService.js';

export default {
  name: "SearchView",
  components: {
    ShowList
  },
  data() {
    return {
      searchQuery: "",
      shows: []
    }
  },
  methods: {
    doSearch() {
      apiService.search(this.searchQuery)
        .then(response => {
          this.shows = response.data;
        })
        .catch(error => {
          // TODO: replace with user facing message
          console.error("error: ", error);
        });
    }
  }
}
</script>

<style scoped>
#search-button {
  cursor: pointer;
}
#search-input, #search-button {
  font-size: 2.5rem;
}
#search-input {
  width: 60%;
  height: 3rem;
  margin-bottom: 2rem;
}
#search-button {
  background-color: #198754;
  border-color: #198754;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
@media (max-width: 500px) {
  #search-button {
    font-size: 1rem;
    height: 2.6rem;
    margin-left: -10px;
  }
  #search-input {
    width: 75%;
    height: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
  }
}
</style>